import React from 'react'
import styled from 'styled-components'
import WoofPackMini from './img/woofpack-only-transparent-mini.png'
import { WoofPage } from './types'

const DesktopNavBar = styled.div<{ active: boolean }>`
    display: flex;
    flex-flow: row;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 758px;

    ${(p) => (p.active ? 'max-height: 150px;' : 'max-height: 0px;')};
    overflow: hidden;
    transition: all 0.5s linear;
    div,
    a {
        cursor: pointer;
        width: 0;
        display: flex;
        flex: 1 1 0px;
        align-items: center;
        justify-content: center;
    }

    a {
        color: lightgray;
        text-decoration: none;
        &:hover {
            color: #fff;
            font-weight: 500;
            background-color: rgba(0, 0, 0, 0.3);
        }
        transition: background-color 0.3s linear;
    }

    margin-bottom: 0.25rem;
`
const HomeButton = styled.div`
    transition: all 0.3s linear;
    opacity: 1;
    padding: 0.5rem;

    &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.3);
    }
    @media screen and (min-width: 1200px) {
        opacity: 0.75;
    }
`
const NavButton = styled.div``

const pages = {
    home: {
        next: 'token',
        prev: 'home',
    },
    token: {
        next: 'collectibles',
        prev: 'home',
    },
    collectibles: {
        next: 'community',
        prev: 'token',
    },
    community: {
        next: 'faq',
        prev: 'collectibles',
    },
    faq: {
        next: 'home',
        prev: 'community',
    },
} as {
    [key in WoofPage]: {
        next: WoofPage
        prev: WoofPage
    }
}

export const NavBar = ({
    currentPage,
    setPageFn,
}: {
    currentPage: WoofPage
    setPageFn: (page: WoofPage) => void
}) => {
    const nextPage = pages[currentPage].next
    const prevPage = pages[currentPage].prev

    return (
        <DesktopNavBar active={currentPage !== 'home'}>
            <a
                tabIndex={0}
                onClick={() => {
                    setPageFn(prevPage)
                }}
            >
                <NavButton>&laquo; {prevPage}</NavButton>
            </a>
            <a
                tabIndex={0}
                onClick={() => {
                    setPageFn('home')
                }}
            >
                <HomeButton>
                    <img src={WoofPackMini} height={'48px'} alt="Home" />
                </HomeButton>
            </a>
            <a
                tabIndex={0}
                onClick={() => {
                    setPageFn(nextPage)
                }}
            >
                <NavButton>{nextPage} &raquo;</NavButton>
            </a>
        </DesktopNavBar>
    )
}
