import React from 'react'
import styled from 'styled-components'
import {
    IconLinkIconContainer,
    LinkContainerAnim,
    LargeIconLink,
    Link,
    TextContainer,
} from './components'
import JupiterLogo from './img/jupiter.svg'
import Hands from './img/hands.svg'
import Rocket from './img/rocket.svg'
import Supply from './img/supply.svg'

const Row = styled.div`
    display: flex;
    flex-flow: column;
`

const InfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    flex-wrap: wrap;

    margin-bottom: 4rem;

    @media screen and (max-height: 576px) {
        margin-bottom: 8rem;
    }
`

const List = styled.ul`
    margin-top: 0;
`

const JupiterContainer = styled(TextContainer)`
    align-items: center;
    width: 70%;
    img {
        width: 150px;
    }

    &:hover {
        animation: ${LinkContainerAnim} linear 2s infinite;
    }
`

const JupiterLink = styled(LargeIconLink)`
    display: flex;
    justify-content: center;
`

const IconContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-flow: column;
    font-size: 1.125rem;
    align-items: center;
    padding: 1rem;
    min-width: 100px;

    border: 1px solid #bbb;
    margin: 0.5rem;
    justify-content: center;
    text-align: center;

    img {
        max-width: 75px;
        margin-bottom: 0.5rem;

        @media screen and (min-width: 992px) {
            max-width: 150px;
        }
    }

    width: 25%;

    @media screen and (min-width: 1200px) {
        font-size: 1.25rem;
    }
`

export const WoofToken = () => {
    return (
        <Row>
            <TextContainer>
                <div>
                    <b>Blockchain</b>: Solana
                </div>
                <div>
                    <b>Symbol</b>: WOOF
                </div>
                <div>
                    <b>Name</b>: WOOFENOMICS
                </div>
                <div
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <b>CA</b>:{' '}
                    <Link
                        href="https://solscan.io/token/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE"
                        target="_blank"
                    >
                        9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE
                    </Link>
                </div>
                <div>
                    <b>Chart</b>:{' '}
                    <List>
                        <li>
                            <Link
                                href="https://dexscreener.com/solana/ehj4zcjthqwhyjms5rbagfrmapjvjq9fgq3mvfdbvhga"
                                target="_blank"
                            >
                                DEX screener (Meteora LP)
                            </Link>
                        </li>
                        <li>
                            <Link
                                href="https://birdeye.so/token/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE?chain=solana"
                                target="_blank"
                            >
                                Birdeye (All pairs)
                            </Link>
                        </li>
                    </List>
                </div>
                <div>
                    <b>MC at ATH</b>: ~100M, November 2021
                </div>
                <div>
                    <b>Current MC</b>: Way too low tbh, pls fix it?
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <b>Thesis</b>: All dogs WOOF! A fairly distributed,
                    community-owned OG dog coin on Solana.
                </div>
            </TextContainer>
            <JupiterLink
                href="https://jup.ag/swap/SOL-9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE"
                target="blank"
            >
                <JupiterContainer>
                    <IconLinkIconContainer>
                        <img src={JupiterLogo} alt="Jupiter icon" />
                    </IconLinkIconContainer>
                    Verified & tradeable on Jupiter!
                </JupiterContainer>
            </JupiterLink>
            <InfoContainer>
                <IconContainer>
                    {' '}
                    <img src={Rocket} />
                    Launched in <b>May, 2021</b>
                </IconContainer>
                <IconContainer>
                    {' '}
                    <img src={Hands} />
                    <b>~30 000</b> holders
                </IconContainer>
                <IconContainer>
                    {' '}
                    <img src={Supply} />
                    <b>17.9B</b> Max/Circulating supply
                </IconContainer>
            </InfoContainer>
        </Row>
    )
}
