import React, { useState } from 'react'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import WoofLogo from './img/woof_with_logo.png'
import SolanaLogo from './img/solanaLogo.svg'
import JupiterLogo from './img/jupiter.svg'
import BirdeyeLogo from './img/birdeye.png'
import DexScreenerLogo from './img/dex-screener-logo-png_seeklogo-527276.png'
import SolScanLogo from './img/solscan.png'
import CoinGeckoLogo from './img/coingecko.png'
import CMCLogo from './img/coinmarketcap.png'
import CryptoComLogo from './img/cryptocom.png'
import XLogo from './img/x-icon.svg'
import { Link } from './components'
import { WoofPage } from './types'

const IconLinkContainer = styled.div`
    display: flex;
    background-color: rgba(0, 0, 0, 0.2);
    border-top: 1px solid #bbb;
    padding: 1rem 0 0.5rem 0;
    align-items: center;
    justify-content: center;
    position: relative;

    span {
        padding-right: 0.5rem;
        position: absolute;
        top: -15px;
    }

    img {
        height: 24px;
        padding-right: 12px;

        &:hover {
            transform: scale(1.1);
        }
    }
`

const WoofCA = styled(Link)`
    display: block;
    color: #fff;
    cursor: pointer;
    font-size: 0.825rem;

    @media screen and (min-width: 576px) {
        font-size: 1rem;
    }
`

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    img {
        display: block;
        pointer-events: none;
        max-width: 100%;
        max-height: 100%;
        height: 50px;

        @media screen and (min-width: 576px) and (min-height: 576px) {
            height: auto;
        }
    }

    @media screen and (min-width: 576px) and (min-height: 576px) {
        padding: 2rem;
    }
`

const InfoBox = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100vw;

    @media screen and (min-width: 576px) {
        width: 100%;
    }
`

const HeaderContainer = styled.div`
    display: flex;
    flex-flow: column;
`

export const Header = ({
    setPageFn,
}: {
    setPageFn: (page: WoofPage) => void
}) => {
    const [copied, setCopied] = useState<boolean>(false)
    return (
        <HeaderContainer>
            <LogoContainer>
                <a tabIndex={0} onClick={() => setPageFn('home')}>
                    <img src={WoofLogo} alt="logo" />
                </a>
            </LogoContainer>

            <InfoBox>
                <div
                    style={{
                        paddingLeft: '0.5rem',
                        display: 'flex',
                    }}
                >
                    <div>
                        <img
                            src={SolanaLogo}
                            style={{
                                height: '15px',
                                paddingRight: '1rem',
                            }}
                        ></img>
                    </div>

                    <WoofCA
                        tabIndex={0}
                        onClick={() => {
                            copy('9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE')
                            setCopied(true)
                            setTimeout(() => setCopied(false), 2000)
                        }}
                    >
                        {!copied && (
                            <>9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE</>
                        )}
                        {copied && <>CA copied to clipboard!</>}
                    </WoofCA>
                </div>
                <IconLinkContainer>
                    <a
                        href="https://solscan.io/token/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE"
                        target="_blank"
                    >
                        <img src={SolScanLogo} alt="Solscan" />
                    </a>
                    <a
                        href="https://jup.ag/swap/SOL-9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE"
                        target="_blank"
                    >
                        <img src={JupiterLogo} alt="Jupiter Aggregator" />
                    </a>
                    <a
                        href="https://dexscreener.com/solana/ehj4zcjthqwhyjms5rbagfrmapjvjq9fgq3mvfdbvhga"
                        target="_blank"
                    >
                        <img src={DexScreenerLogo} alt="DEX Screener" />
                    </a>
                    <a
                        href="https://birdeye.so/token/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE?chain=solana"
                        target="_blank"
                    >
                        <img src={BirdeyeLogo} alt="Birdeye" />
                    </a>
                    <a
                        href="https://www.coingecko.com/en/coins/woof"
                        target="_blank"
                    >
                        <img src={CoinGeckoLogo} alt="CoinGecko" />
                    </a>
                    <a
                        href="https://coinmarketcap.com/currencies/woof"
                        target="_blank"
                    >
                        <img src={CMCLogo} alt="CoinMarketCap" />
                    </a>
                    <a href="https://crypto.com/price/woof" target="_blank">
                        <img src={CryptoComLogo} alt="Crypto.com" />
                    </a>
                    <a href="https://x.com/WoofSolana" target="_blank">
                        <img src={XLogo} alt="X" />
                    </a>
                </IconLinkContainer>
            </InfoBox>
        </HeaderContainer>
    )
}
