import styled, { keyframes } from 'styled-components'

export const TextContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-flow: column;
    font-size: 1rem;
    padding: 1rem;
    border: 1px solid #bbb;
    margin: 0.5rem;

    @media screen and (min-width: 992px) {
        font-size: 1.125rem;
    }
`

export const Link = styled.a`
    color: lightgray;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 2px;
        background: linear-gradient(
            90deg,
            rgba(153, 69, 255, 1) 0%,
            rgba(20, 245, 149, 1) 100%
        );
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover::before {
            left: 0;
            right: auto;
            width: 100%;
        }
    }
    text-decoration: none;
    &:hover {
        color: #fff;
        font-weight: 500;
    }
`

export const LinkContainerAnim = keyframes`
    0% {
        background-color: rgba(0, 0, 0, 0.65);
    }
    50% {
        background-color: rgba(0, 0, 0, 0.3);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.65);
    }
`

export const LargeIconLink = styled.a`
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    height: 100%;
    &:visited {
        color: #fff;
    }
    border-radius: 0.5rem;
`

export const IconLinkIconContainer = styled.div`
    padding: 0.5rem;
`

export const IconTitle = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    text-align: center;
`

export const IconLinkContentContainer = styled(TextContainer)`
    align-items: center;
    padding: 1rem;

    @media screen and (min-width: 576px) {
        width: 200px;
        height: 330px;
    }

    &:hover {
        animation: ${LinkContainerAnim} linear 2s infinite;
    }
`

export const IconDescription = styled.div`
    font-size: 1rem;
    text-align: center;
`

export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    flex-flow: column;
`

export const ContentContainer = styled.div`
    display: flex;
    flex-flow: row;
    font-size: 1.325rem;
    line-height: 2.5rem;
    flex-wrap: wrap;
    padding: 2rem 2rem 2rem 2rem;

    margin-bottom: 4rem;
    @media screen and (max-height: 576px) {
        margin-bottom: 8rem;
    }
`

export const IconLink = styled.img`
    height: 25px;
    width: auto;
    margin: 1rem;

    &:hover {
        transform: scale(1.1);
    }

    @media screen and (min-width: 1200px) {
        height: 42px;
    }

    @media screen and (min-width: 1600px) {
        height: 50px;
    }
`
