import React from 'react'
import styled from 'styled-components'
import Woofpack from './img/woofpack-transparent2.png'
import { Link } from './components'
import { WoofPage } from './types'

const WoofpackMenuContainer = styled.div`
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: start;
    flex-flow: row;
    font-family: inherit;
    color: #fff;
    margin: 1rem 0 0 0;
    font-size: 1.25rem;
    position: relative;
    justify-content: center;
`

const AnotherMenuContainer = styled.div`
    position: absolute;
    display: flex;
    height: 100%;
    justify-content: center;
`

const WoofpackBackground = styled.img`
    height: fit-content;
    max-width: 100%;
    max-height: 100%;
`
const WoofpackMenuItemsContainer = styled.div`
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 22%;
    left: 5%;
    font-size: 0.75rem;

    @media screen and (min-height: 576px) {
        font-size: 1rem;
    }
    @media screen and (min-width: 576px) and (min-height: 576px) {
        font-size: 1.125rem;
    }

    @media screen and (min-width: 900px) {
        font-size: 1.25rem;
    }
`

export const Home = ({
    setPageFn,
}: {
    setPageFn: (page: WoofPage) => void
}) => {
    const [thoon, setThoon] = React.useState<boolean>(false)

    return (
        <WoofpackMenuContainer className="content">
            <AnotherMenuContainer>
                <WoofpackBackground src={Woofpack} />
                <WoofpackMenuItemsContainer>
                    <div>
                        <Link
                            tabIndex={0}
                            onClick={() => {
                                setPageFn('token')
                            }}
                        >
                            Token
                        </Link>
                    </div>
                    <div>
                        <Link
                            tabIndex={0}
                            onClick={() => setPageFn('collectibles')}
                        >
                            Collectibles
                        </Link>
                    </div>
                    <div>
                        <Link
                            tabIndex={0}
                            onClick={() => setPageFn('community')}
                        >
                            Community
                        </Link>
                    </div>
                    <div>
                        <Link tabIndex={0} onClick={() => setPageFn('faq')}>
                            FAQ
                        </Link>
                    </div>
                    <div>
                        <Link
                            tabIndex={0}
                            onClick={() => {
                                setThoon(true)
                                setTimeout(() => setThoon(false), 2000)
                            }}
                        >
                            {!thoon ? '???' : 'Thoooooon....'}
                        </Link>
                    </div>
                    <div>
                        <Link
                            href="https://links.woof.ventures"
                            target="_blank"
                        >
                            Link tree&nbsp;&#11008;
                        </Link>
                    </div>
                </WoofpackMenuItemsContainer>
            </AnotherMenuContainer>
        </WoofpackMenuContainer>
    )
}
