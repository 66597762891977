import React, { useEffect } from 'react'
import styled from 'styled-components'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { MainContainer } from './components'
import './App.css'
import { WoofToken } from './WoofToken'
import { NFTs } from './NFTs'
import { WoofPage } from './types'
import { NavBar } from './NavBar'
import { Header } from './Header'
import { Home } from './Home'
import { Community } from './Community'
import { Faq } from './Faq'

const Copyright = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.75rem;
    margin: 0.25rem 0 0 0;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    bottom: 0;

    @media screen and (min-width: 576px) {
        font-size: 1rem;
    }
`

const ContentContainer = styled.div`
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;

    @media screen and (min-width: 576px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

function App() {
    const [page, setPage] = React.useState<WoofPage>('home')
    let nodeRef = React.useRef(null)

    // Switch to correct page if linked directly with an anchor
    useEffect(() => {
        const pageUrl = `${window.location.href
            .replace(window.location.origin, '')
            .replace('/#', '')}`
        if (
            pageUrl &&
            pageUrl.length > 0 &&
            pageUrl !== '/' &&
            pageUrl !== page
        ) {
            setPage(pageUrl as WoofPage)
        }
    }, [window.location.href])

    return (
        <>
            <MainContainer>
                <Header setPageFn={setPage} />
                <NavBar currentPage={page} setPageFn={setPage} />

                <SwitchTransition mode={'out-in'}>
                    <CSSTransition
                        key={page}
                        nodeRef={nodeRef}
                        addEndListener={(done: unknown) => {
                            nodeRef?.current?.addEventListener(
                                'transitionend',
                                done,
                                false
                            )
                        }}
                        classNames="fade"
                        unmountOnExit
                    >
                        <div
                            ref={nodeRef}
                            style={{
                                display: 'block',
                                width: '100%',
                            }}
                        >
                            <ContentContainer>
                                {page !== 'home' && (
                                    <div className="content">
                                        {page === 'token' && <WoofToken />}
                                        {page === 'collectibles' && <NFTs />}
                                        {page === 'community' && <Community />}
                                        {page === 'faq' && <Faq />}
                                    </div>
                                )}

                                {page === 'home' && (
                                    <Home setPageFn={setPage} />
                                )}
                            </ContentContainer>
                        </div>
                    </CSSTransition>
                </SwitchTransition>

                <Copyright>
                    &copy; WOOFers of Solana {new Date().getFullYear()}
                </Copyright>
            </MainContainer>
        </>
    )
}

export default App
