import React from 'react'
import styled from 'styled-components'
import RedditLogo from './img/reddit-icon.svg'
import TelegramLogo from './img/telegram.webp'
import DiscordLogo from './img/discord-icon.svg'
import XLogo from './img/x-icon.svg'
import {
    LargeIconLink,
    IconLinkContentContainer,
    IconLinkIconContainer,
    IconTitle,
    IconDescription,
} from './components'

const SocialLogo = styled.img`
    width: 64px;

    @media screen and (min-width: 576px) {
        width: 96px;
    }
`
const Row = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: end;
    margin-bottom: 4rem;

    @media screen and (min-width: 576px) {
        margin-bottom: 2rem;
    }
`

export const Community = () => (
    <Row>
        <LargeIconLink href="http://discord.gg/Cbe7eyHD75" target="blank">
            <IconLinkContentContainer>
                <IconLinkIconContainer>
                    <SocialLogo src={DiscordLogo} alt="Discord icon" />
                </IconLinkIconContainer>
                <IconTitle>WOOF Discord</IconTitle>
                <IconDescription>
                    Our most active meeting point where you'll always stay up to
                    date on all things WOOF!
                </IconDescription>
            </IconLinkContentContainer>
        </LargeIconLink>
        <LargeIconLink href="https://t.me/woofenomics" target="blank">
            <IconLinkContentContainer>
                <IconLinkIconContainer>
                    <SocialLogo src={TelegramLogo} alt="Telegram icon" />
                </IconLinkIconContainer>
                <IconTitle>WOOF Telegram</IconTitle>
                <IconDescription>
                    Visit our growing community on Telegram! More WOOFers
                    needed!
                </IconDescription>
            </IconLinkContentContainer>
        </LargeIconLink>
        <LargeIconLink
            href="https://x.com/i/communities/1855498014053335082"
            target="blank"
        >
            <IconLinkContentContainer>
                <IconLinkIconContainer>
                    <SocialLogo src={XLogo} alt="X icon" />
                </IconLinkIconContainer>
                <IconTitle>WOOF X Community</IconTitle>
                <IconDescription>
                    Our brand new WOOF Army X community bringing together all
                    WOOFers on X!
                </IconDescription>
            </IconLinkContentContainer>
        </LargeIconLink>
        <LargeIconLink href="https://reddit.com/r/WoofSolana" target="_blank">
            <IconLinkContentContainer>
                <IconLinkIconContainer>
                    <SocialLogo src={RedditLogo} alt="Reddit icon" />
                </IconLinkIconContainer>
                <IconTitle>WOOF on Reddit</IconTitle>
                <IconDescription>
                    Currently mostly a curiosity but might be an interesting
                    read for those that appreciate a little nostalgia trip!
                </IconDescription>
            </IconLinkContentContainer>
        </LargeIconLink>
    </Row>
)
