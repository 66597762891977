import React from 'react'
import WoofGenArt from './img/woof-gen-art.jpg'
import Woofer from './img/woofer.png'
import DigiPups from './img/digipups.png'
import Woofpack from './img/woofpack.jpg'
import styled from 'styled-components'
import { ContentContainer, Link } from './components'

const NFTImg = styled.img`
    margin-bottom: 1rem;
    border-radius: 1rem;
    max-height: 150px;
    margin-right: 1.5rem;

    @media screen and (min-width: 576px) {
        max-height: 150px;
    }

    @media screen and (min-width: 992px) {
        max-height: 200px;
    }
`

const NFTContainer = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.65);
    padding: 1rem;
    margin: 0.5rem;
    border: 1px solid #bbb;
    flex-flow: column;
    justify-content: start;
    align-items: start;

    ul {
        margin-top: 0;
        list-style: square;
        padding-left: 1.5rem;

        li {
            margin-top: 0;
            @media screen and (min-width: 992px) {
                margin-top: 0.5rem;
            }
        }
    }
`
const NFTDetails = styled.div`
    display: flex;
    flex-flow: row;
    width: 550px;
    justify-content: start;
    align-items: start;

    @media screen and (min-width: 992px) {
        width: 750px;
    }
`

const NFTsContainer = styled(ContentContainer)`
    padding: 1rem 0 0 0;
    flex-flow: column;
`

const NFTHeader = styled.h2`
    font-family: 'Kalam', system-ui;
    margin: 0;
    font-size: 1.25rem;
    width: 100%;
    position: absolute;
    right: 15px;
    top: 15px;
    text-align: right;

    @media screen and (min-width: 992px) {
        font-size: 1.5rem;
    }
`

const NFTInfo = styled.div<{ mobile: boolean }>`
    font-size: 1rem;
    line-height: 1.5rem;
    display: ${(p) => (p.mobile ? 'block' : 'none')};
    overflow-wrap: break-word;
    max-width: 100%;

    @media screen and (min-width: 992px) {
        display: ${(p) => (p.mobile ? 'none' : 'block')};
        font-size: 1.125rem;
        max-width: 450px;
    }
`

const NFTDetailsContainer = styled.div`
    display: flex;
    flex-flow: row;
`

const WoofersInfo = () => (
    <ul>
        <li>Minted for 0.5 SOL a piece in November - December, 2022.</li>

        <li>Supply at launch: 5 555.</li>
        <li>Granted access to the now defunct WOOF DAO.</li>
        <li>
            Non-custodial staking for $WOOF rewards available on{' '}
            <Link
                href="https://staking.woof.ventures"
                target="_blank"
                rel="noreferrer"
            >
                staking.woof.ventures
            </Link>
            .
        </li>
        <li>
            Play around with WOOFer traits on the{' '}
            <Link
                href="https://woofers-playground.woof.ventures"
                target="_blank"
                rel="noreferrer"
            >
                WOOFers playground
            </Link>
            .
        </li>
        <li>
            Available for trading on e.g.{' '}
            <Link
                href="https://www.tensor.trade/trade/woof"
                rel="noreferrer"
                target="_blank"
            >
                Tensor
            </Link>
        </li>
    </ul>
)

const GenArtInfo = () => (
    <ul>
        <li>Airdropped to $WOOF holders in September, 2021.</li>
        <li>Supply at launch: 6 421.</li>
        <li>
            Available for trading on{' '}
            <Link
                href="https://solsea.io/c/61abd1c8880668bc1442973a"
                rel="noreferrer"
                target="_blank"
            >
                SolSea
            </Link>
            .
        </li>
    </ul>
)

const DigiPupsInfo = () => (
    <ul>
        <li>Minted for free in April, 2023.</li>
        <li>Supply at launch: 276.</li>
        <li>
            A collaboration with{' '}
            <Link
                href="https://twitter.com/AI_LabNFT"
                target="_blank"
                rel="noreferrer"
            >
                Clouds (AI Labs)
            </Link>
            .
        </li>
        <li>Each one is unique and has a unique name.</li>
        <li>Mint tokens were distributed through community games.</li>
        <li>
            Available for trading on{' '}
            <Link
                href="https://magiceden.io/marketplace/digipup"
                rel="noreferrer"
                target="_blank"
            >
                Magic Eden
            </Link>
            .
        </li>
    </ul>
)

const WoofpackInfo = () => <>Upcoming collection... Stay tuned.</>
export const NFTs = () => (
    <>
        <NFTsContainer>
            <NFTContainer>
                <NFTDetailsContainer>
                    <NFTImg
                        src={WoofGenArt}
                        alt="WOOF - Generated Art Series"
                    />
                    <NFTDetails>
                        <NFTHeader>Gen Art Series</NFTHeader>
                        <NFTInfo mobile={false}>{GenArtInfo()}</NFTInfo>
                    </NFTDetails>
                </NFTDetailsContainer>
                <NFTInfo mobile={true}>{GenArtInfo()}</NFTInfo>
            </NFTContainer>
            <NFTContainer>
                <NFTDetailsContainer>
                    <NFTImg src={Woofer} alt="WOOFers" />
                    <NFTDetails>
                        <NFTHeader>WOOFers</NFTHeader>
                        <NFTInfo mobile={false}>{WoofersInfo()}</NFTInfo>
                    </NFTDetails>
                </NFTDetailsContainer>
                <NFTInfo mobile={true}>{WoofersInfo()}</NFTInfo>
            </NFTContainer>
            <NFTContainer>
                <NFTDetailsContainer>
                    <NFTImg src={DigiPups} alt="DigiPups" />
                    <NFTDetails>
                        <NFTHeader>DigiPups</NFTHeader>
                        <NFTInfo mobile={false}>{DigiPupsInfo()}</NFTInfo>
                    </NFTDetails>
                </NFTDetailsContainer>
                <NFTInfo mobile={true}>{DigiPupsInfo()}</NFTInfo>
            </NFTContainer>
            <NFTContainer>
                <NFTDetailsContainer>
                    <NFTImg src={Woofpack} alt="Woofpack" />
                    <NFTDetails>
                        <NFTHeader>Woofpack</NFTHeader>
                        <NFTInfo mobile={false}>{WoofpackInfo()}</NFTInfo>
                    </NFTDetails>
                </NFTDetailsContainer>
                <NFTInfo mobile={true}>{WoofpackInfo()}</NFTInfo>
            </NFTContainer>
        </NFTsContainer>
    </>
)
