import React from 'react'
import styled from 'styled-components'

import { ContentContainer, TextContainer } from './components'

const FaqImage = styled.img`
    padding: 0;
    width: 250px;

    @media screen and (min-width: 576px) {
        width: 400px;
    }
`

const Description = styled.div`
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
`

const FaqContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Faq = () => (
    <FaqContainer>
        <TextContainer style={{ padding: '0', textAlign: 'center' }}>
            <FaqImage
                src={
                    'https://media1.tenor.com/m/gkh-pAQf7yMAAAAd/woof-woofpack.gif'
                }
                alt="WOOF FAQ"
            />
            <Description>More comprehensive FAQ thoon.</Description>
        </TextContainer>
    </FaqContainer>
)
